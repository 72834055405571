@tailwind base;
@tailwind components;
@tailwind utilities;


html {
    scroll-behavior: smooth;
    box-sizing: border-box;
}

/* Header */
header {
    font-family: 'Playfair Display';
}


nav > ul {
    transition: height 0.3s ease-out;
}

html nav a.active {
    font-weight: bold;
    color: #2563eb;
}

html nav a:hover {
    font-weight: bold;
    color: #2563eb;
}

html.dark nav a.active {
    font-weight: bold;
    color: #bfdbfe;
}

html.dark nav a:hover {
    font-weight: bold;
    color: #bfdbfe;
}

main {
    font-family: 'Mulish', sans-serif;
}


.tech-stack-list > div{
    height: 4rem;
    width: 4rem;
    transition: transform 0.3s;
}

.tech-stack-list > div:hover, .project-tech > div {
    transform: translateY(2px);
    cursor: pointer;
}

/* Light Mode */
html #header {
    background-color: #ffffff;
}

html #home, html  #projects, html .project-tech > div, html .contact-icons > div > div, html .footer {
    background-color: #f9f9f9;
}

html #about, html #contact, html .project-card, html .tech-stack-list > div{
    background-color: #ffffff;
}

/* Dark Mode */
html.dark #header {
    background-color: #263544;
}

html.dark #home, html.dark #projects, html.dark .project-tech > div, html.dark .contact-icons > div > div, html.dark .footer {
    background-color: #1b242f;
}

html.dark #about, html.dark #contact, html.dark .project-card, html.dark .tech-stack-list > div{
    background-color: #263544;
}

/* Home */
.profile-img {
    animation: morph 8s ease-in-out infinite;
    background-image: url(/public/sian-dp.jpg);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    transition: all 1s ease-in-out;

}

html .profile-img {
    border: 3px solid black;
}

html.dark .profile-img {
    border: 3px solid white;
}
@keyframes morph {
    0% {
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }
    50% {
        border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
    }
    100% {
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }
}
/* Floating Icons */

.laptop-icon, .game-icon, .guitar-icon {
    -webkit-animation: move 2s infinite;
    animation: move 2s infinite;
}

@keyframes move {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

.laptop-icon {
    animation-delay: 0s;
}

.game-icon {
    animation-delay: 0.5s; 
}

.guitar-icon {
    animation-delay: 1s;
}

/* Projects */
.project-tech > div, .contact-icons .contact{
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

/* Contact */
html .contact-form > *:not(.last-contact) {
    background-color: #f9f9f9;
}

html.dark .contact-form > *:not(.last-contact) {
    background-color: #1b242f;
}