.slick-slider.slick-initialized {
    z-index: 1;
  }
  .slick-arrow.slick-prev {

    z-index: 2;
  }
  .slick-arrow.slick-next {

    z-index: 2;
  }

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 24px;
  color:gray;
}

.slick-prev {
  left: 30px; /* Adjust the horizontal position of the previous arrow */
}

.slick-next {
  right: 30px; /* Adjust the horizontal position of the next arrow */
}

html.dark .slick-dots li button:before{
    color: white;
}